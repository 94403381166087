import { useClient } from '@/components/shared/utilities/AppProvider/AppProvider';
import { UserSingleDocument } from '@/interfaces/IUserResource';
import useSWR from 'swr';

export function useUser() {
	const client = useClient();
	const {
		data: user,
		mutate: mutateUser,
		error,
		isValidating,
	} = useSWR(
		'user',
		async (key) => {
			const data = await client.find<UserSingleDocument['data']>(key, 'me');

			return data;
		},
		{
			shouldRetryOnError: false,
		}
	);

	return {
		user,
		mutateUser,
		userError: error,
		isUserValidating: isValidating,
		isUserLoading: !user && !error,
	};
}
