import { useOutsideClick } from '@chakra-ui/react-use-outside-click';
import { useRouter } from 'next/router';
import { FC, useRef, useState } from 'react';
import styles from './LanguageMenu.module.scss';
import Link from 'next/link';

interface LanguageMenuProps {
	colorType?: 'primary' | 'secondary';
}

export const LanguageMenu: FC<LanguageMenuProps> = ({ colorType = 'primary' }) => {
	const ref = useRef(null);
	const [shown, setShown] = useState(false);
	const { pathname, query, locale: currentLocale, locales } = useRouter();

	const languagePickerClass = `${styles.languagePicker} ${styles[colorType]} ${
		shown ? styles.languagePickerActive : ''
	}`;

	useOutsideClick({
		ref: ref,
		handler: () => setShown(false),
	});

	const handleLocaleChange = (locale: string) => {
		const queryString = new URLSearchParams(query as Record<string, string>).toString();
		const newUrl = `/${locale}${pathname}${queryString ? `?${queryString}` : ''}`;

		// Trigger a full page reload
		window.location.href = newUrl;
	};

	return (
		<div className={languagePickerClass} ref={ref}>
			<a className={styles.languagePickerTrigger} onClick={() => setShown((prev) => !prev)}>
				{currentLocale?.toUpperCase()}
			</a>
			{shown && (
				<div className={styles.languagePickerContent}>
					<ul className="dropdownList">
						{locales?.map((locale) => {
							return (
								<li className="dropdownListItem" key={locale}>
									<Link
										className="dropdownListLink"
										key={locale}
										href={{ pathname, query }}
										locale={locale}
										onClick={() => {
											setShown(false);
											handleLocaleChange(locale);
										}}
									>
										{locale.toUpperCase()}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
};
