const category = 'User';

export const login = {
	category,
	action: 'Click on Log In',
};

export const register = {
	category,
	action: 'Click on Register',
};

export const registerSucccess = {
	category,
	action: 'User Created',
};

export const loginFacebook = {
	category,
	action: 'Click on Log In With FB',
};

export const loginTwitter = {
	category,
	action: 'Click on Log In With TW',
};

export const loginInstagram = {
	category,
	action: 'Click on Log in With Instagram',
};
