const category = 'Add Story';

export const addStory = {
	category,
	action: 'Click On Add Story',
};

export const pinAdded = {
	category,
	action: 'Pin Added (Success on Add Pin)',
};

export const storyAdded = {
	category,
	action: 'Story Added (Success)',
};
