const category = 'Footer Navigation';

export const visit = {
	category,
	action: 'Click On Visit',
};

export const explore = {
	category,
	action: 'Click On Explore',
};

export const share = {
	category,
	action: 'Click On Share',
};

export const shop = {
	category,
	action: 'Click On Shop',
};

export const profile = {
	category,
	action: 'Click On Profile',
};

export const donate = {
	category,
	action: 'Click On Donate',
};

export const addStory = {
	category,
	action: 'Click On Add Story',
};
