import classNames from 'classnames';
import { Component } from 'react';

import styles from './DesktopNav.module.scss';

import GA from '@/analytics/ga/components/GA';
import { Navigation } from '@/analytics/ga/index';
import Button from '@/components/core/Button/Button';

import { NavLink } from '@/components/shared/navigations/CoreNavigation/components/NavLink/NavLink';
import { GET_YOUR_GUIDE_LINK, SHOP_LINK } from '@/utils/constants';

import { LanguageMenu } from '@/components/shared/navigations/CoreNavigation/components/LanguageMenu/LanguageMenu';
import { withTranslation, WithTranslation } from 'next-i18next';
import { NextRouter, withRouter } from 'next/router';
import { useUser } from '@/hooks/useUser';
import { withUser } from '@/components/shared/utilities/WithUser/WithUser';

interface WithRouterProps {
	router: NextRouter;
}

export interface IDesktopNavProps extends WithTranslation, WithRouterProps, ReturnType<typeof useUser> {
	className?: string;
	colorType?: string;
	colorTypeActive?: string;
	languagePickerColor?: 'primary' | 'secondary';
}

class DesktopNav extends Component<IDesktopNavProps> {
	render() {
		const { className, colorType, colorTypeActive, languagePickerColor, t, router, user } = this.props;

		const desktopNavClassNames = classNames(styles.desktopNav, className);

		const globalNavLinkClassNames = classNames(styles.globalNavLink, {
			[styles['globalNavLink' + colorType]]: colorType,
		});

		const languageMenuClassNames = classNames(styles.globalNavLink, {
			[styles['languageDropdown' + colorType]]: colorType,
		});

		const globalNavLinkActiveClassNames = classNames(styles.globalNavLinkActive, {
			[styles['globalNavLinkActive' + colorTypeActive]]: colorTypeActive,
		});

		const globalNavLinkDropdownClassNames = classNames(
			styles.globalNavLink,
			{
				[styles['globalNavLink' + colorType]]: colorType,
			},
			styles.globalNavDropdown
		);

		const globalNavDropdownToggleClassNames = classNames(styles.globalNavDropdownToggle, {
			[styles['globalNavDropdownToggle' + colorType]]: colorType,
		});

		return (
			<div className={desktopNavClassNames}>
				<ul className={styles.globalNav}>
					<li className={styles.globalNavItem}>
						<GA event={Navigation.visit}>
							<NavLink
								href="/visit"
								className={globalNavLinkClassNames}
								activeClassName={globalNavLinkActiveClassNames}
							>
								{t('visit')}
							</NavLink>
						</GA>
					</li>
					<li className={styles.globalNavItem}>
						<GA event={Navigation.explore}>
							<NavLink
								href="/explore"
								className={globalNavLinkClassNames}
								activeClassName={globalNavLinkActiveClassNames}
							>
								{t('explore')}
							</NavLink>
						</GA>
					</li>
					<li className={styles.globalNavItem}>
						<GA event={Navigation.book}>
							<NavLink href="/book" className={globalNavLinkClassNames} activeClassName={globalNavLinkActiveClassNames}>
								{t('book')}
							</NavLink>
						</GA>
					</li>
					<li className={styles.globalNavItem}>
						<GA event={Navigation.share}>
							<NavLink
								href="/share"
								className={globalNavLinkClassNames}
								activeClassName={globalNavLinkActiveClassNames}
							>
								{t('share')}
							</NavLink>
						</GA>
					</li>
					<li className={globalNavLinkDropdownClassNames}>
						<span className={styles.globalNavDropdownTrigger}>
							{t('contribute')}
							<span className={globalNavDropdownToggleClassNames}></span>
						</span>
						<div className={styles.globalNavDropdownItems}>
							<ul className="dropdownList">
								<li className="dropdownListItem">
									<GA event={Navigation.donate}>
										<NavLink href={{ query: { open: 'contribute' } }} shallow={true} className="dropdownListLink">
											{t('send-your-item')}
										</NavLink>
									</GA>
								</li>
								<li className="dropdownListItem">
									<NavLink href={{ query: { open: 'share-story' } }} shallow={true} className="dropdownListLink">
										{t('share-story')}
									</NavLink>
								</li>
								<li className="dropdownListItem">
									<NavLink href={{ query: { open: 'faq' } }} shallow={true} className="dropdownListLink">
										{t('faq')}
									</NavLink>
								</li>
							</ul>
						</div>
					</li>
					<li className={styles.globalNavItem}>
						<a href={SHOP_LINK} className={globalNavLinkClassNames}>
							{t('shop')}
						</a>
					</li>
					<li className={styles.globalNavItem}>
						<a href={GET_YOUR_GUIDE_LINK} className={globalNavLinkClassNames}>
							{t('tickets')}
						</a>
					</li>
					<li className={styles.globalNavItem}>
						<LanguageMenu colorType={languagePickerColor} />
					</li>
					{user ? (
						<li className={styles.globalNavItem}>
							<GA event={Navigation.profile}>
								<NavLink
									href="/my-profile"
									className={globalNavLinkClassNames}
									activeClassName={globalNavLinkActiveClassNames}
								>
									{t('my-profile')}
								</NavLink>
							</GA>
						</li>
					) : null}
				</ul>
				<div className={styles.userNav}>
					{!user ? (
						<div className={styles.userNavItem}>
							<Button
								color={colorType}
								pill
								hollow
								onClick={() => router.replace({ query: { open: 'login' } }, undefined, { shallow: true })}
							>
								{t('login')}
							</Button>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default withTranslation()(withRouter(withUser(DesktopNav)));
