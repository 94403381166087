import GA from '@/analytics/ga/components/GA';
import { Navigation } from '@/analytics/ga/index';
import { GET_YOUR_GUIDE_LINK, SHOP_LINK } from '@/utils/constants';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'next-i18next';
import { Component } from 'react';
import styles from './MobileNav.module.scss';

import Button from '@/components/core/Button/Button';
import { NavLink } from '@/components/shared/navigations/CoreNavigation/components/NavLink/NavLink';
import { LanguageMenu } from '@/components/shared/navigations/CoreNavigation/components/LanguageMenu/LanguageMenu';
import { useUser } from '@/hooks/useUser';
import { withUser } from '@/components/shared/utilities/WithUser/WithUser';
import { destroyUserSession } from '@/mutations/session';
import { mutate } from 'swr';

export interface IDesktopNavProps extends WithTranslation, ReturnType<typeof useUser> {
	className?: string;
	open?: boolean;
	colorType?: string;
	colorTypeActive?: string;
}

class MobileNav extends Component<IDesktopNavProps> {
	state: {
		open: boolean;
	};

	constructor(props: IDesktopNavProps) {
		super(props);
		this.state = { open: props.open };
	}

	onHamburgerClickHandler() {
		this.setState({ open: !this.state.open });
	}

	async logout() {
		await destroyUserSession();

		await mutate(() => true, null, { revalidate: false });
	}

	render() {
		const { user, className, colorType, t } = this.props;
		const { open } = this.state;

		const mobileNavClassNames = classNames(styles.mobileNav, className);

		const mobileNavHamburgerClassNames = classNames(styles.mobileNavHamburger, {
			[styles['mobileNavHamburger' + colorType]]: colorType,
			[styles.mobileNavHamburgerOpen]: open,
		});

		const mobileNavContentClassNames = classNames(styles.mobileNavContent, {
			[styles.mobileNavContentOpen]: open,
		});

		return (
			<div className={mobileNavClassNames}>
				<div className={mobileNavHamburgerClassNames} onClick={this.onHamburgerClickHandler.bind(this)}>
					<span></span>
					<span></span>
					<span></span>
				</div>
				<div className={mobileNavContentClassNames}>
					<div className={styles.mobileNavHeader}>{/* <LanguagePicker /> */}</div>
					<div className={styles.mobileNavList}>
						<NavLink href="/" className={styles.mobileNavListItem} activeClassName={styles.mobileNavListItemActive}>
							{t('home')}
						</NavLink>
						<GA event={Navigation.visit}>
							<NavLink
								href="/visit"
								className={styles.mobileNavListItem}
								activeClassName={styles.mobileNavListItemActive}
							>
								{t('visit')}
							</NavLink>
						</GA>
						<GA event={Navigation.explore}>
							<NavLink
								href="/explore"
								className={styles.mobileNavListItem}
								activeClassName={styles.mobileNavListItemActive}
							>
								{t('explore')}
							</NavLink>
						</GA>
						<GA event={Navigation.book}>
							<NavLink
								href="/book"
								className={styles.mobileNavListItem}
								activeClassName={styles.mobileNavListItemActive}
							>
								{t('book')}
							</NavLink>
						</GA>
						<GA event={Navigation.share}>
							<NavLink
								href="/share"
								className={styles.mobileNavListItem}
								activeClassName={styles.mobileNavListItemActive}
							>
								{t('share')}
							</NavLink>
						</GA>
						<a href={SHOP_LINK} className={styles.mobileNavListItem}>
							{t('shop')}
						</a>
						<a href={GET_YOUR_GUIDE_LINK} className={styles.mobileNavListItem}>
							{t('tickets')}
						</a>
						<LanguageMenu />
					</div>
					<div className={styles.mobileNavSeparator}></div>
					{user ? (
						<div className={styles.mobileNavList}>
							<GA event={Navigation.profile}>
								<NavLink
									href="/my-profile"
									className={styles.mobileNavListItem}
									activeClassName={styles.mobileNavListItemActive}
								>
									{t('my-profile')}
								</NavLink>
							</GA>
							<button className={styles.mobileNavListItem} onClick={this.logout.bind(this)}>
								{t('logout')}
							</button>
						</div>
					) : (
						<div className={styles.mobileNavList}>
							<NavLink href={{ query: { open: 'login' } }} shallow={true} className={styles.mobileNavListItem}>
								{t('login')}
							</NavLink>
							<NavLink href={{ query: { open: 'register' } }} shallow={true} className={styles.mobileNavListItem}>
								{t('join')}
							</NavLink>
						</div>
					)}
					<div className={styles.mobileNavAccessories}>
						<GA event={Navigation.addStory}>
							<NavLink
								href={{ query: { open: 'share-story' } }}
								shallow={true}
								className={styles.mobileNavAccessoriesLink}
							>
								<Button hollow>{t('pin--share-a-breakup')}</Button>
							</NavLink>
						</GA>
						<br />
						<GA event={Navigation.donate}>
							<NavLink
								href={{ query: { open: 'contribute' } }}
								shallow={true}
								className={styles.mobileNavAccessoriesLink}
							>
								<Button hollow>{t('contribute')}</Button>
							</NavLink>
						</GA>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(withUser(MobileNav));
