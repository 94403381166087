import Modal from '@/components/core/Modal/Modal';
import { ModalFallback } from '@/components/shared/modals/components/ModalFallback/ModalFallback';
import { usePrevious } from '@/hooks/use-previous';
import { ModalTypes } from '@/utils/constants';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC, useCallback, useState } from 'react';

const ModalLogin = dynamic(() => import('./components/ModalLogin/ModalLogin').then((mod) => mod.ModalLogin), {
	loading: ModalFallback,
});
const ModalRegister = dynamic(
	() => import('./components/ModalRegister/ModalRegister').then((mod) => mod.ModalRegister),
	{ loading: ModalFallback }
);
const ModalContribute = dynamic(
	() => import('./components/ModalContribute/ModalContribute').then((mod) => mod.ModalContribute),
	{ loading: ModalFallback }
);
const ModalShareStory = dynamic(
	() => import('./components/ModalShareStory/ModalShareStory').then((mod) => mod.ModalShareStory),
	{ loading: ModalFallback }
);
const ModalChangePassword = dynamic(
	() => import('./components/ModalChangePassword/ModalChangePassword').then((mod) => mod.ModalChangePassword),
	{ loading: ModalFallback }
);
const ModalFAQ = dynamic(() => import('./components/ModalFAQ/ModalFAQ').then((mod) => mod.ModalFAQ), {
	loading: ModalFallback,
});
const ModalPressKit = dynamic(
	() => import('./components/ModalPressKit/ModalPressKit').then((mod) => mod.ModalPressKit),
	{ loading: ModalFallback }
);
const ModalInPress = dynamic(() => import('./components/ModalInPress/ModalInPress').then((mod) => mod.ModalInPress), {
	loading: ModalFallback,
});
const ModalContact = dynamic(() => import('./components/ModalContact/ModalContact').then((mod) => mod.ModalContact), {
	loading: ModalFallback,
});
const ModalForgotPassword = dynamic(
	() => import('./components/ModalForgotPassword/ModalForgotPassword').then((mod) => mod.ModalForgotPassword),
	{ loading: ModalFallback }
);
const ModalAboutMuseum = dynamic(
	() => import('./components/ModalAboutMuseum/ModalAboutMuseum').then((mod) => mod.ModalAboutMuseum),
	{ loading: ModalFallback }
);
const ModalHostAnExhibition = dynamic(
	() => import('./components/ModalHostAnExhibition/ModalHostAnExhibition').then((mod) => mod.ModalHostAnExhibition),
	{ loading: ModalFallback }
);
const ModalForgotPasswordConfirmation = dynamic(
	() =>
		import('./components/ModalForgotPasswordConfirmation/ModalForgotPasswordConfirmation').then(
			(mod) => mod.ModalForgotPasswordConfirmation
		),
	{ loading: ModalFallback }
);

const MapModal = dynamic(() => import('./components/MapModal/MapModal').then((mod) => mod.MapModal), {
	loading: ModalFallback,
});

const ModalDonateConfirmation = dynamic(
	() =>
		import('./components/ModalDonateConfirmation/ModalDonateConfirmation').then((mod) => mod.ModalDonateConfirmation),
	{
		loading: ModalFallback,
	}
);

const DeleteStoryModal = dynamic(
	() => import('./components/DeleteStoryModal/DeleteStoryModal').then((mod) => mod.DeleteStoryModal),
	{
		loading: ModalFallback,
	}
);

const ModalMap = {
	[ModalTypes.LOGIN]: ModalLogin,
	[ModalTypes.REGISTER]: ModalRegister,
	[ModalTypes.BREAKUPS_MAP]: MapModal,
	[ModalTypes.CONTRIBUTE]: ModalContribute,
	[ModalTypes.DONATE]: ModalContribute,
	[ModalTypes.SHARE_STORY]: ModalShareStory,
	[ModalTypes.EDIT_STORY]: ModalShareStory,
	[ModalTypes.DELETE_STORY]: DeleteStoryModal,
	[ModalTypes.CHANGE_PASSWORD]: ModalChangePassword,
	[ModalTypes.FAQ]: ModalFAQ,
	[ModalTypes.PRESS_KIT]: ModalPressKit,
	[ModalTypes.IN_PRESS]: ModalInPress,
	[ModalTypes.CONTACT]: ModalContact,
	[ModalTypes.FORGOT_PASSWORD]: ModalForgotPassword,
	[ModalTypes.ABOUT_MUSEUM]: ModalAboutMuseum,
	[ModalTypes.HOST_AN_EXIBITION]: ModalHostAnExhibition,
	[ModalTypes.FORGOT_PASSWORD_CONFIRMATION]: ModalForgotPasswordConfirmation,
	[ModalTypes.DONATE_CONFIRM]: ModalDonateConfirmation,
};

export const Modals: FC = () => {
	const { query, push, pathname } = useRouter();
	const modalType = query?.open as string;
	const prevModalType = usePrevious(modalType);
	const [isClosing, setIsClosing] = useState(false);
	const deferredModalType = isClosing ? prevModalType : modalType;
	const isOpen = modalType !== undefined;

	const ModalComponent = ModalMap[deferredModalType];

	const onRequestClose = useCallback(() => {
		push(
			{
				pathname: pathname,
				query: { ...query, open: [] },
			},
			undefined,
			{ shallow: true }
		);
		setIsClosing(true);
	}, []);

	const onAfterClose = useCallback(() => {
		setIsClosing(false);
	}, []);

	return (
		<Modal isOpen={isOpen} onRequestClose={onRequestClose} onAfterClose={onAfterClose} onEscape={onRequestClose}>
			{ModalComponent !== undefined && <ModalComponent onClose={onRequestClose} />}
		</Modal>
	);
};
