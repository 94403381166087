import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import CookieConsent from 'react-cookie-consent';
import styles from './CookieBanner.module.scss';

export const CookieBanner: FC = () => {
	const { t } = useTranslation();

	return (
		<CookieConsent
			location="top"
			buttonText={t('ok-got-it')}
			cookieName="accepts-brokenships-cookies"
			expires={365}
			containerClasses={styles.cookieBanner}
			contentClasses={styles.cookieBannerContent}
			buttonWrapperClasses={styles.cookieBannerButtonWrapper}
			buttonClasses={styles.cookieBannerButton}
			buttonStyle={{
				background: 'white',
				color: '#242424',
				fontSize: '14px',
				fontWeight: '500',
				opacity: 0.5,
				padding: '0 8px',
				lineHeight: '24px',
			}}
		>
			<p>{t('we-use-cookies-to-improve-your')}</p>
		</CookieConsent>
	);
};
