import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './Loader.module.scss';

export interface ICoreLoaderProps {
	type: 'Primary' | 'Secondary' | 'Ternary';
	className?: string;
}

export const CORE_LOADER_TYPE = {
	PRIMARY: 'Primary',
	SECONDARY: 'Secondary',
	TERNARY: 'Ternary',
} as const;

class CoreLoader extends Component<ICoreLoaderProps> {
	render() {
		const { type = CORE_LOADER_TYPE.PRIMARY, className } = this.props;

		const loaderClassNames = classNames(
			styles.loader,
			{
				[styles['loader' + type]]: type,
			},
			className
		);

		return <div className={loaderClassNames}></div>;
	}
}

export const Loader = CoreLoader;

/**
 * @deprecated Use named export Loader instead
 */
export default CoreLoader;
