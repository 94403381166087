const category = 'Donate';

export const donate = {
	category,
	action: 'Click On Donate',
};

export const donateSubmitted = {
	category,
	action: 'Donation Submitted ',
};
