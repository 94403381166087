import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { makeVariant } from '../utils/eventUtils';

const sendEvent = (event, callback, ...args) => {
	ReactGA.event(event);

	if (typeof cb === 'function') {
		callback(...args);
	}
};

const GA = ({ event, children, ...rest }, context) => {
	return React.cloneElement(children, {
		...rest,
		onClick: sendEvent.bind(this, makeVariant(event, context.variant), children.props.onClick),
	});
};

GA.propTypes = {
	children: PropTypes.element.isRequired,
	event: PropTypes.object.isRequired,
};

GA.contextTypes = {
	variant: PropTypes.string,
};

export default GA;
