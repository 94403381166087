import { FC } from 'react';
import styles from './ModalFallback.module.scss';
import { Loader } from '@/components/core/Loader/Loader';

export interface IModalFallbackProps {}

export const ModalFallback: FC<IModalFallbackProps> = () => {
	return (
		<div className={styles.modal}>
			<div className={styles.contentFallback}>
				<Loader type="Primary" />
			</div>
		</div>
	);
};
