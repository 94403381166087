import Button from '@/components/core/Button/Button';
import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'next-i18next';
import Link from 'next/link';
import { Component } from 'react';

import styles from './BottomNavigation.module.scss';

export const linkType = {
	COLLECTION: 'Collection',
	STORIES: 'Stories',
	VISIT: 'Visit',
} as const;

const linkTypes = {
	[linkType.COLLECTION]: {
		link: '/explore',
		text: 'go-to-collection',
	},
	[linkType.STORIES]: {
		link: '/share',
		text: 'go-to-stories',
	},
	[linkType.VISIT]: {
		link: '/visit',
		text: 'plan-a-visit',
	},
} as const;

export interface IBottomNavigationProps extends WithTranslation {
	leftLink?: string;
	rightLink?: string;
}

class BottomNavigation extends Component<IBottomNavigationProps> {
	render() {
		const color = 'Dimmed';
		const { leftLink = linkType.COLLECTION, rightLink = linkType.STORIES, t } = this.props;

		const leftLinkData = linkTypes[leftLink];
		const rightLinkData = linkTypes[rightLink];

		const bottomNavClassNames = classNames(styles.bottomNav, {
			[styles['bottomNav' + color]]: color,
		});

		const leftLinkClassNames = classNames(styles.bottomNavLink, {
			[styles['bottomNavLink' + leftLink]]: leftLink,
		});

		const rightLinkClassNames = classNames(styles.bottomNavLink, {
			[styles['bottomNavLink' + rightLink]]: rightLink,
		});

		return (
			<div className={bottomNavClassNames}>
				<div className={styles.tiltWrapper}>
					<div className={styles.linkContainer}>
						<div className={leftLinkClassNames}>
							<Link href={leftLinkData.link} className={styles.bottomNavLinkButton}>
								<Button size={Button.size.BIG} hollow className={styles.bottomNavButton}>
									{t(leftLinkData.text)}
								</Button>
							</Link>
						</div>
						<div className={rightLinkClassNames}>
							<Link href={rightLinkData.link} className={styles.bottomNavLinkButton}>
								<Button size={Button.size.BIG} hollow className={styles.bottomNavButton}>
									{t(rightLinkData.text)}
								</Button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(BottomNavigation);
