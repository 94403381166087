import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { FC, ReactNode } from 'react';

interface ILinkProps extends NextLinkProps {
	activeClassName?: string;
	className?: string;
	children?: ReactNode;
}

export const NavLink: FC<ILinkProps> = ({ href, className, activeClassName, children, ...rest }) => {
	const { pathname } = useRouter();
	const active = pathname !== '/' ? pathname.includes(href.toString()) : false;

	return (
		<NextLink href={href} className={active ? `${className} ${activeClassName}` : className} {...rest}>
			{children}
		</NextLink>
	);
};
