const category = 'Clicks In Feed';

export const addStory = {
	category,
	action: 'Click on News',
};

export const pinAdded = {
	category,
	action: 'Click on Collection',
};

export const storyAdded = {
	category,
	action: 'Click on Story',
};
