import React, { Component } from 'react';
import Link from 'next/link';

import styles from './CoreNavigation.module.scss';
import Logo from '@/components/core/Logo/Logo';
import DesktopNav from '@/components/shared/navigations/CoreNavigation/components/DesktopNav/DesktopNav';
import MobileNav from '@/components/shared/navigations/CoreNavigation/components/MobileNav/MobileNav';

export enum ColorTypeOptions {
	PRIMARY = 'Primary',
	PRIMARY_LIGHT = 'PrimaryLight',
	SECONDARY = 'Secondary',
	DIMMED = 'Dimmed',
	LIGHT = 'Light',
	DARK = 'Dark',
}

export interface INavigationProps {
	colorType?: string;
	colorTypeActive?: string;
	languagePickerColor?: 'primary' | 'secondary';
}

export default class Navigation extends Component<INavigationProps> {
	static defaultProps = {
		colorType: ColorTypeOptions.LIGHT,
		colorTypeActive: ColorTypeOptions.LIGHT,
		languagePickerColor: 'primary',
	};

	static colorType = ColorTypeOptions;

	render() {
		const { colorType, colorTypeActive, languagePickerColor } = this.props;

		return (
			<div className={styles.navigation}>
				<div className={styles.navigationContainer}>
					<Link href="/" className={styles.navigationLogo}>
						<Logo className={styles['navigationLogo' + colorType]} />
					</Link>
					<DesktopNav
						className={styles.navigationDesktop}
						colorType={colorType}
						languagePickerColor={languagePickerColor}
						colorTypeActive={colorTypeActive}
					/>
					<MobileNav className={styles.navigationMobile} colorType={colorType} />
				</div>
			</div>
		);
	}
}
