import classNames from 'classnames';
import { Component } from 'react';
import { default as ReactModal } from 'react-modal';
import styles from './Modal.module.scss';

export interface IModalProps extends ReactModal.Props {
	onEscape?: (event: KeyboardEvent) => void;
}

export default class Modal extends Component<IModalProps> {
	componentDidMount() {
		document.addEventListener('keydown', this.onKeydown.bind(this));
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.onKeydown.bind(this));
	}

	onKeydown(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			this.props.onEscape?.(event);
		}
	}

	render() {
		const overlayClassName = classNames(styles.modalOverlay, 'js-modal-overlay');

		return (
			<ReactModal
				overlayClassName={overlayClassName}
				className={styles.modalContent}
				closeTimeoutMS={500}
				{...this.props}
			/>
		);
	}
}
